export const solutionCardsData = [
  {
    title: 'Gestion de la documentation clients',
    details: 'Centralise et gère les documents nécessaires pour l\'identification et la vérification des clients.',
    description: 'Centralisation et gestion simplifiée des documents clients.',
    icons: [
      '/img/solutions/gestion-docs/folder-svgrepo-com.svg',
      '/img/solutions/gestion-docs/document-svgrepo-com.svg',
      '/img/solutions/gestion-docs/archive-svgrepo-com.svg'
    ]
  },
  {
    title: 'Remédiation',
    details: 'Automatise le suivi et la correction des dossiers clients non conformes aux exigences réglementaires.',
    description: 'Automatisation du suivi et de la correction des non-conformités.',
    icons: [
      '/img/solutions/remediation/settings-svgrepo-com.svg',
      '/img/solutions/remediation/refresh-svgrepo-com.svg',
      '/img/solutions/remediation/checklist-svgrepo-com.svg'
    ]
  },
  {
    title: 'Gestion de fiche client',
    details: 'Organise et met à jour les informations essentielles des clients dans une fiche centralisée.',
    description: 'Organisez et mettez à jour facilement les informations clients.',
    icons: [
      '/img/solutions/fiche-client/user-svgrepo-com.svg',
      '/img/solutions/fiche-client/id-card-svgrepo-com.svg',
      '/img/solutions/fiche-client/edit-svgrepo-com.svg'
    ]
  },
  {
    title: 'Suivi des transactions',
    details: 'Surveille et analyse les transactions pour détecter toute activité suspecte ou non conforme.',
    description: 'Surveillez les transactions pour détecter toute anomalie.',
    icons: [
      '/img/solutions/suivi-des-transactions/transaction-svgrepo-com.svg',
      '/img/solutions/suivi-des-transactions/eye-svgrepo-com.svg',
      '/img/solutions/suivi-des-transactions/analyze-svgrepo-com.svg'
    ]
  },
  {
    title: 'Recherche gel des avoirs',
    details: 'Identifie les clients et les actifs soumis à des restrictions légales ou à un gel des avoirs.',
    description: 'Identifiez les actifs soumis à des restrictions légales.',
    icons: [
      '/img/solutions/gel-des-avoirs/freeze-svgrepo-com.svg',
      '/img/solutions/gel-des-avoirs/lock-svgrepo-com.svg',
      '/img/solutions/gel-des-avoirs/search-svgrepo-com.svg'
    ]
  },
  {
    title: 'Gestion des déclarations de soupçon',
    details: 'Facilite la création et le suivi des déclarations de soupçon pour lutter contre le blanchiment d\'argent et le financement du terrorisme.',
    description: 'Simplifiez les déclarations de soupçon contre le blanchiment d’argent.',
    icons: [
      '/img/solutions/declarations-soupcon/warning-svgrepo-com.svg',
      '/img/solutions/declarations-soupcon/file-svgrepo-com.svg',
      '/img/solutions/declarations-soupcon/alert-svgrepo-com.svg'
    ]
  },
  {
    title: 'Gestion des examens renforcés',
    details: 'Gère les examens renforcés pour les clients présentant des risques élevés, conformément aux exigences réglementaires.',
    description: 'Examinez les clients à risque conformément aux réglementations.',
    icons: [
      '/img/solutions/examens-renforces/shield-svgrepo-com.svg',
      '/img/solutions/examens-renforces/search-document-svgrepo-com.svg',
      '/img/solutions/examens-renforces/check-svgrepo-com.svg'
    ]
  },
  {
    title: 'Support',
    details: 'Fournir une assistance aux utilisateurs pour toute question ou problème rencontré.',
    description: 'Assistance rapide pour toutes vos questions et problèmes.',
    icons: [
      '/img/solutions/support/hand-holding-svgrepo-com.svg',
      '/img/solutions/support/help-circle-svgrepo-com.svg',
      '/img/solutions/support/support-svgrepo-com.svg'
    ]
  }
];