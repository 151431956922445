import React from 'react';
import './LegalMentions.css';
import Background from '../../components/Background/Background';

function LegalMentions() {
  return (
    <Background background='linear-gradient(to left, rgba(0, 123, 73, 0.85), rgba(15, 48, 34, 0.85))'>
    <div className="legal-mentions">
      <h1>Mentions Légales</h1>

      <section>
        <h2>1. Éditeur du Site</h2>
        <p>
          Nom de la société : Poly Swan Tech <br />
          Adresse : 38 Cours Blaise Pascal, Evry-Courcouronnes <br />
          Téléphone : +33 1 69 23 63 44 <br />
          Email : contact@polyswantech.com <br />
          RCS : 899 888 671 R.C.S. Evry <br />
          Directeur de la publication : Poly Swan Tech
        </p>
      </section>

      <section>
        <h2>2. Hébergement</h2>
        <p>
          Hébergeur : OVH <br />
          Adresse : 2 Rue Kellermann, 59100 Roubaix, France <br />
          Téléphone : +33 9 72 10 10 07 <br />
          Site web : <a href="https://www.ovh.com" target="_blank" rel="noopener noreferrer">www.ovh.com</a>
        </p>
      </section>

      <section>
        <h2>3. Propriété Intellectuelle</h2>
        <p>
          Le site et chacun des éléments qui le composent, incluant mais non limité aux textes,
          images, logos, etc., sont la propriété exclusive de Poly Swan Tech. Toute reproduction,
          distribution, modification ou utilisation de ces éléments sans autorisation préalable est
          interdite.
        </p>
      </section>

      <section>
        <h2>4. Responsabilité</h2>
        <p>
          Poly Swan Tech ne saurait être tenu responsable pour tout dommage direct ou indirect
          résultant de l'accès ou de l'utilisation de ce site, incluant l'inaccessibilité, les
          pertes de données, les détériorations ou virus pouvant affecter l'équipement informatique
          de l'utilisateur.
        </p>
      </section>

      <section>
        <h2>5. Protection des Données Personnelles</h2>
        <p>
          Les informations recueillies sur ce site font l’objet d’un traitement informatique destiné
          à [expliquez la finalité de la collecte des données, par exemple : "l'envoi de newsletters" ou "le traitement des demandes de contact"]. Conformément à la loi « Informatique et Libertés », vous
          disposez d’un droit d’accès, de rectification et de suppression des informations vous
          concernant.
        </p>
      </section>

      <section>
        <h2>6. Cookies</h2>
        <p>
          Ce site utilise des cookies pour améliorer l’expérience utilisateur. Vous pouvez choisir
          de désactiver les cookies dans les paramètres de votre navigateur.
        </p>
      </section>
    </div>
    </Background>
  );
}

export default LegalMentions;