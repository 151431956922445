import React, { useEffect } from 'react';
import './TeamMember.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const TeamMember = ({ name, profession, image, contacts }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const handleMouseEnter = (e) => {
    e.target.classList.add('transition');
  };
  
  return (
    <div data-aos="flip-right" onMouseEnter={handleMouseEnter} className="team-member">
      <img className="team-member-image" src={'/img/team/' + (image ? image : 'autre.jpg')} alt={name} />
      <div className="team-member-info">
        <h3>{name}</h3>
        <p>{profession}</p>
        <div className="team-member-contacts">
          {contacts.map((contact, index) => (
            <a key={index} href={contact.link} className="contact-link" target="_blank" rel="noopener noreferrer">
              {contact.icon}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamMember;