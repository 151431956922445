import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Header.css';
import { MdMenu, MdClose } from 'react-icons/md';
import { FaPhoneAlt } from 'react-icons/fa';

function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleMenu = () => setMenuOpen(!isMenuOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLinkClick = (anchor) => {
    if (location.pathname !== '/') {
      // Si l'utilisateur n'est pas sur "/", redirigez d'abord
      navigate('/');
      setTimeout(() => {
        document.querySelector(anchor).scrollIntoView({ behavior: 'smooth' });
      }, 100);
    } else {
      // Sinon, défilez directement vers l'ancre
      document.querySelector(anchor).scrollIntoView({ behavior: 'smooth' });
    }
    setMenuOpen(false);
  };

  return (
        <nav ref={navRef} className={`navigation ${isMenuOpen ? 'active' : ''}`}>
          <button
            className="hamburger"
            onClick={toggleMenu}
            aria-label="Toggle Menu"
          >
            {isMenuOpen ? (
              <MdClose className="hamburger-icon close-icon" />
            ) : (
              <MdMenu className="hamburger-icon" />
            )}
          </button>
          <div className="logo-container-menu">
            <img src='./img/company-icon/polyswantech_white.png' alt="Logo Poly Swan Tech" />
          </div>
          <div class="container-nav">
          <ul className={`nav-list ${isMenuOpen ? 'show' : ''}`}>
            <li><a href="#about" onClick={(e) => { e.preventDefault(); handleLinkClick('#about'); }}>Qui sommes-nous ?</a></li>
            <li><a href="#values" onClick={(e) => { e.preventDefault(); handleLinkClick('#values'); }}>Nos valeurs</a></li>
            <li><a href="#solutions" onClick={(e) => { e.preventDefault(); handleLinkClick('#solutions'); }}>Nos solutions</a></li>
            <li><a href="#ecosysteme-entreprise" onClick={(e) => { e.preventDefault(); handleLinkClick('#ecosysteme-entreprise'); }}>Notre écosystème</a></li>
            <li><a href="#team" onClick={(e) => { e.preventDefault(); handleLinkClick('#team'); }}>Notre équipe</a></li>
            <li><a id="contact-link" href="#contact" onClick={(e) => { e.preventDefault(); handleLinkClick('#contact'); }}>{!isMenuOpen ? <FaPhoneAlt /> : 'Nous contacter'}</a></li>
          </ul>
          </div>
        </nav>
  );
}

export default Header;