import React, { useEffect } from 'react';
import './AboutUs.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

function AboutUs() {

  useEffect(() => {
    AOS.init({
      duration: 500,
      once: true,
    });
  }, []);

  return (
    <section id="about" className="section">
      <div className="about-content">
        <div data-aos="fade-right" className="about-header">
          <h2>À propos de nous</h2>
          <p>
            Poly Swan Tech est un acteur clé dans la Fintech, spécialisé dans l'accompagnement des entreprises du secteur financier. Nous concevons des solutions innovantes pour faciliter la gestion des risques, la conformité réglementaire et renforcer la transparence des opérations, afin de favoriser une finance plus responsable et durable.
          </p>
        </div>

        <div className="about-details">
          <div data-aos="fade-right" className="about-image">
            <video id="myVideo" src="./video/BE SMART - Linterview de Badr Oualadi (Poly Swan Tech) par Aurélie Planeix.mp4" autoPlay loop muted controls width="300"/>
          </div>
          <div data-aos="fade-left" className="about-text">
            <h3>Notre raison d'être</h3>
            <p>
              Chez Poly Swan Tech, nous avons pour mission de rendre la finance plus agile, transparente et responsable. En fournissant des solutions robustes, nous permettons à nos clients d’anticiper les risques et de répondre aux défis réglementaires tout en bâtissant un avenir financier plus sécurisé et durable.
            </p>
          </div>
        </div>

        <div data-aos="fade-right" className="about-mission">
          <h3>Ce que nous faisons</h3>
          <p>
            Nous croyons en une finance où la transparence et la responsabilité sont au cœur des préoccupations. Poly Swan Tech propose des solutions sur-mesure pour les banques, assureurs, Fintechs et autres acteurs du secteur financier, permettant une gestion proactive des risques et une conformité parfaite. Ensemble, transformons les défis réglementaires en opportunités de performance et de confiance.
          </p>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;