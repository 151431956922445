import React, { useState, useEffect } from "react";
import "./ServiceCard.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

const ServiceCard = ({ title = "title", description = "description", icons = [], details = "details" }) => {

    const [isFlipped, setIsFlipped] = useState(false);

    useEffect(() => {
        AOS.init({
          duration: 1000,
          once: true,
        });
      }, []);

    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    };

    const handleMouseEnter = (e) => {
        e.currentTarget.classList.add('transition');
        e.currentTarget.setAttribute('data-aos', '');
      };

    return (
        <div data-aos="fade-up" data-aos-duration="1000" onMouseEnter={handleMouseEnter} className={`service-card ${isFlipped ? "flipped no-border" : ""}`}>
            <div className="card-front">
                <div className="icon-section">
                    <div className="icon-examples">
                        {icons.map((icon, index) => (
                            <img key={index} src={icon} alt={`Icon ${index + 1}`} />
                        ))}
                    </div>
                </div>
                <div className="description-section">
                    <h3>{title}</h3>
                    <p>{description}</p>
                    <button className="read-more" onClick={handleFlip}>Lire plus</button>
                </div>
            </div>
            <div className="card-back">
                <div className="details-section">
                    <h3>{title}</h3>
                    <p>{details}</p>
                    <button className="go-back" onClick={handleFlip}>
                        Retour
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ServiceCard;