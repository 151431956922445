import { FaEnvelope, FaLinkedin, FaPhoneAlt } from "react-icons/fa";

// Couleur utilisée pour les icônes
const iconColor = "#c9a227";

export const teamMembersData = [
  {
    name: "Badr OUALADI",
    profession: "CEO",
    image: "Badr.jpg",
    contacts: [
      { type: "Mail", icon: <FaEnvelope style={{ color: iconColor }} />, link: "mailto:badr.oualadi@montaigne-advisory.com" },
      { type: "LinkedIn", icon: <FaLinkedin style={{ color: iconColor }} />, link: "https://www.linkedin.com/in/badr-oualadi-62286457/" },
      { type: "Téléphone", icon: <FaPhoneAlt style={{ color: iconColor }} />, link: "tel:+33 629865902" },
    ],
  },
  {
    name: "Fabio LOUSADO",
    profession: "Développeur Lead Fullstack",
    image: "Fabio.jpg",
    contacts: [
      { type: "LinkedIn", icon: <FaLinkedin style={{ color: iconColor }} />, link: "https://www.linkedin.com/in/fabio-lousado-874a40206/" },
    ],
  },
  {
    name: "Romain ZABETH",
    profession: "Développeur Fullstack",
    image: "Romain.jpg",
    contacts: [
      { type: "LinkedIn", icon: <FaLinkedin style={{ color: iconColor }} />, link: "https://www.linkedin.com/in/romain-zabeth-29690a231/" },
    ],
  },
  {
    name: "Youssef KHATTAB",
    profession: "Développeur Fullstack",
    image: "Youssef.jpg",
    contacts: [
      { type: "LinkedIn", icon: <FaLinkedin style={{ color: iconColor }} />, link: "https://www.linkedin.com/in/youssefkhattab/" },
    ],
  },
  {
    name: "Cris LEFORT",
    profession: "Chargé administrateur réseaux et systèmes",
    image: "Cris.jpg",
    contacts: [
      { type: "LinkedIn", icon: <FaLinkedin style={{ color: iconColor }} />, link: "https://www.linkedin.com/in/cris-lefort-0850962a0/" },
    ],
  },
  {
    name: "Ziyad BOUGDOU",
    profession: "Chargé réseaux et systèmes",
    image: "Ziyad.jpg",
    contacts: [
      { type: "LinkedIn", icon: <FaLinkedin style={{ color: iconColor }} />, link: "https://www.linkedin.com/in/ziyad-bougdou-49b0932a0/" },
    ],
  },
];