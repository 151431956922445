import React, { useEffect } from 'react';
import TeamMember from '../../components/TeamMember/TeamMember';
import './Team.css'; 
import Background from '../../components/Background/Background';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { teamMembersData } from '../../data/teamMembers';

const Team = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <Background
      background="linear-gradient(to left, rgba(0, 123, 73, 0.85), rgba(15, 48, 34, 0.85))"
    >
    <section data-aos="fade-up" id="team" className="section team-section py-5">
      <h2 className="team-title">Notre équipe</h2>
      <div className="team-container">
        {teamMembersData.map((member, index) => (
          <TeamMember
            key={index}
            name={member.name}
            profession={member.profession}
            image={member.image}
            contacts={member.contacts}
          />
        ))}
      </div>
    </section>
    </Background>
  );
};

export default Team;