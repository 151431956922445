import React from 'react';
import './Background.css';

function Background({ children, backgroundImage, videoSrc, z_index = 1, opacity = 1, background = 'transparent' }) {
  return (
    <div className="background-container">
      {/* Si une source de vidéo est fournie, afficher la vidéo, sinon afficher l'image */}
      {videoSrc ? (
        <video className="background-video" autoPlay loop muted>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <div 
          className="background-image" 
          style={{ 
            backgroundImage: `url(${backgroundImage})`, 
            opacity: opacity 
          }} 
        />
      )}
      
      <div 
        className="background-overlay" 
        style={{ background }} 
      />
      
      <div className="background-content" 
            style={{zIndex: `${z_index}`,

        }}>
        {children}
      </div>
    </div>
  );
}

export default Background;