import React, { useEffect } from 'react';
import './Values.css';
import valuesData from '../../data/valuesData';
import Background from '../../components/Background/Background';
import AOS from 'aos';
import 'aos/dist/aos.css';


function Values() {
  
useEffect(() => {
  AOS.init({
    duration: 1000,
    once: true,
  });
}, []);

const handleMouseEnter = (e) => {
  e.currentTarget.classList.add('transition');
  e.currentTarget.setAttribute('data-aos', '');
};

  return (
    <Background background="linear-gradient(to left, rgba(0, 123, 73, 0.85), rgba(15, 48, 34, 0.85))">
    <section id="values" className="section values-content">
      <h2 data-aos="fade-up">Nos Valeurs</h2>
      <div className="value-container">
        <div className="values-grid">
          {valuesData.map((value, index) => (
            <div data-aos="slide-up" onMouseEnter={handleMouseEnter} className="value-card" key={index}>
              <div className='title-icon-container'>
              {value.icon}
              <h3>{value.title}</h3>
              </div>
              <p>{value.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  </Background>
  );
}

export default Values;