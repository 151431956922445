import React, { useEffect } from 'react';
import useSendCV from '../../hooks/useSendCV';
import './ContactForm.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

function ContactForm() {
  const { isButtonDisabled, handleFileChange, handleNomChange, handleEmailChange, sendCV } = useSendCV();

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  return (
    <form onSubmit={sendCV} className="join-us-form">
      <div className="form-group">
        <label htmlFor="name">Nom  <span style={{ color: 'red' }}>*</span></label>
        <input onChange={handleNomChange} type="text" id="name" name="name" placeholder="Entrez votre nom complet" required />
      </div>
      <div className="form-group">
        <label htmlFor="email">Email  <span style={{ color: 'red' }}>*</span></label>
        <input onChange={handleEmailChange} type="email" id="email" name="email" placeholder="Entrez votre adresse email" required />
      </div>
      <div className="form-group">
        <label htmlFor="message">Message</label>
        <textarea id="message" name="message" rows="4" placeholder="Votre message (facultatif)" />
      </div>
      <div className="form-group">
        <label htmlFor="upload-cv" className="btn-join">Joindre votre CV</label>
        <input
          type="file"
          id="upload-cv"
          name="files"
          accept=".pdf,.doc,.docx"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </div>
      <button id="validateBtn" type="submit" disabled={isButtonDisabled}>
        Envoyer
      </button>
    </form>
  );
}

export default ContactForm;