import React, { useEffect } from 'react';
import './IntroBanner.css';
import Background from '../../components/Background/Background';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Header() {

  useEffect(() => {

    AOS.init({
      duration: 1000,
      once: true,
    });

  }, []);

  return (
    <Background backgroundImage="/img/fond/home.webp" z_index="100" videoSrc="./video/fond/fde.mp4">
      <section id="intro-banner">
        <div data-aos="fade-up" className="content-wrapper">
          <div className="logo-container">
            <img src="./img/company-icon/polyswantech_white.png" alt="Logo de Poly Swan Tech" loading="lazy" />
          </div>
          <h1 className="header-title">
            RÉVOLUTIONNEZ VOS SERVICES DE CONFORMITÉ RÉGLEMENTAIRE
          </h1>
        </div>
      </section>
    </Background>
  );
}

export default Header;