import React, { useEffect, useState } from 'react';
import './EcosystemeEntreprise.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

function EcosystemeEntreprise() {
  const [flippedCards, setFlippedCards] = useState({
    afge: false,
    montaigne: false,
    expertus: false,
  });

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const toggleFlip = (key) => {
    setFlippedCards((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <section id="ecosysteme-entreprise" className="ecosysteme-section">
      <div data-aos="fade-up" className="eco-header">
        <h2>Notre écosystème</h2>
        <p>Découvrez nos partenaires et collaborateurs qui enrichissent nos solutions.</p>
      </div>

      <div className="eco-content">
        <div className="eco-partners">
          <div
            data-aos="slide-right"
            className="eco-before flip-card eco-color-afges"
            onClick={() => toggleFlip('afge')}
          >
            <div
              className={`eco-partner flip-card-inner ${
                flippedCards.afge ? 'flipped' : ''
              }`}
            >
              <div className="flip-card-front">
                <a href="https://www.afges.com" target="_blank" rel="noreferrer">
                  <img src="./img/company-icon/afges.webp" alt="Afges" />
                </a>
              </div>
              <div className="flip-card-back">
                <p>AFGES, spécialiste de la formation depuis 1986, offre des formations certifiantes en banque, assurance et finance, en présentiel ou à distance. L'entreprise se distingue par son expertise, ses formateurs spécialisés et son engagement RSE.</p>
              </div>
            </div>
          </div>

          <div
            data-aos="slide-up"
            className="eco-before flip-card eco-color-montaigne"
            onClick={() => toggleFlip('montaigne')}
          >
            <div
              className={`eco-partner flip-card-inner ${
                flippedCards.montaigne ? 'flipped' : ''
              }`}
            >
              <div className="flip-card-front">
                <a href="https://www.montaigne-advisory.com/" target="_blank" rel="noreferrer">
                  <img src="./img/company-icon/montaigne.webp" alt="Montaigne Advisory" />
                </a>
              </div>
              <div className="flip-card-back">
                <p>Montaigne Advisory Executive est un cabinet indépendant spécialisé en conformité et contrôle interne pour la banque, l’assurance et la gestion d’actifs. Il accompagne ses clients avec expertise et agilité dans un cadre réglementaire exigeant.</p>
              </div>
            </div>
          </div>

          <div
            data-aos="slide-left"
            className="eco-before flip-card eco-color-expertus"
            onClick={() => toggleFlip('expertus')}
          >
            <div
              className={`eco-partner flip-card-inner ${
                flippedCards.expertus ? 'flipped' : ''
              }`}
            >
              <div className="flip-card-front">
                <a href="https://www.expertus-consulting.com/" target="_blank" rel="noreferrer">
                  <img src="./img/company-icon/expertus.webp" alt="Expertus Consulting" />
                </a>
              </div>
              <div className="flip-card-back">
                <p>Expertus Consulting aide les entreprises à mettre en place des dispositifs réglementaires, gérer les risques et renforcer la conformité, tout en offrant des formations et du soutien lors des audits réglementaires.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EcosystemeEntreprise;