import React, { useEffect } from 'react';
import Background from '../../components/Background/Background';
import './Solutions.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import { solutionCardsData } from '../../data/solutionCards'; // Importez les données adaptées

function Solutions() {

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <Background
      backgroundImage="/img/fond/nos-solutions.webp"
      background="linear-gradient(135deg, #f7f7f7, #e0e0e0);"
      opacity={0.4}
    >
      <section id="solutions" className="section py-5">
      <h2 data-aos="fade-up">Nos solutions</h2>
        <div className="solutions-container">
        {solutionCardsData.map((card, index) => (
          <ServiceCard
            key={index}
            title={card.title}
            description={card.description}
            icons={card.icons}
            details={card.details}
          />
        ))}
        </div>
      </section>
    </Background>
  );
}

export default Solutions;