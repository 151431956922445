import { useState, useEffect } from 'react';

function useSendCV() {
  const [formState, setFormState] = useState({
    nom: '',
    email: '',
    file: null,
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const maxSize = 25 * 1024 * 1024; // Taille maximale en octets
  const acceptedTypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  // Mise à jour dynamique de l'état de validation
  useEffect(() => {
    const { nom, email } = formState;
    setIsButtonDisabled(!(nom.trim() && email.trim()));
  }, [formState]);

  const handleChange = (field) => (e) => {
    setFormState((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (!acceptedTypes.includes(selectedFile.type)) {
        alert("Seuls les fichiers PDF ou Word sont acceptés.");
        return;
      }
      if (selectedFile.size > maxSize) {
        alert("Le fichier dépasse la taille maximale autorisée de 25 MB.");
        return;
      }
      setFormState((prev) => ({ ...prev, file: selectedFile }));
      alert("Fichier joint avec succès !");
    } else {
      setFormState((prev) => ({ ...prev, file: null }));
    }
  };

  const sendCV = (e) => {
    e.preventDefault();

    const { nom, email, file } = formState;
    const message = e.target.message.value.trim();
    const subject = "Envoi de CV Poly Swan Tech";
    const to = "contact@polyswantech.com";

    // FormData pour l'envoi des données
    const formData = new FormData();
    formData.append('name', nom);
    formData.append('email', email);
    formData.append('message', message || '');
    if (file) formData.append('files', file);

    setIsButtonDisabled(true); // Désactiver le bouton pendant le chargement
    fetch(`/mail/send?subject=${encodeURIComponent(subject)}&to=${encodeURIComponent(to)}`, {
      method: 'POST',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) throw new Error("Erreur lors de l'envoi du fichier.");
        return response.json();
      })
      .then(() => {
        alert("Votre CV a été envoyé avec succès !");
        e.target.reset();
        setFormState({ nom: '', email: '', file: null }); // Réinitialise les champs
      })
      .catch((error) => {
        console.error("Erreur :", error);
        alert("Erreur lors de l'envoi du fichier.");
      })
      .finally(() => setIsButtonDisabled(false)); // Réactiver le bouton
  };

  return {
    isButtonDisabled,
    handleFileChange,
    handleNomChange: handleChange('nom'),
    handleEmailChange: handleChange('email'),
    sendCV,
  };
}

export default useSendCV;