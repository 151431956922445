import { FaBalanceScale, FaHandshake, FaLightbulb, FaUserCheck, FaLeaf } from 'react-icons/fa';

const valuesData = [
  {
    icon: <FaHandshake className="value-icon" />,
    title: 'Transparence et Intégrité',
    description:
      "Nous valorisons la transparence et l'intégrité. Nos relations avec les clients reposent sur une communication claire et une confiance mutuelle.",
  },
  {
    icon: <FaLightbulb className="value-icon" />,
    title: 'Innovation Continue',
    description:
      'Nous repoussons les limites de la technologie pour offrir des solutions en phase avec les évolutions du secteur, toujours à la pointe.',
  },
  {
    icon: <FaLeaf className="value-icon" />,
    title: 'Responsabilité et Durabilité',
    description:
      'Nous croyons en une croissance éthique et durable, en appliquant des pratiques responsables et en contribuant positivement à la société.',
  },
  {
    icon: <FaUserCheck className="value-icon" />,
    title: 'Engagement envers le Client',
    description:
      'Chaque client est unique. Nos solutions sont adaptées pour répondre précisément aux besoins de chacun, assurant performance et personnalisation.',
  },
  {
    icon: <FaBalanceScale className="value-icon" />,
    title: 'Notre Mission',
    description:
      'Offrir des solutions innovantes pour faciliter la conformité réglementaire et aider nos clients à relever les défis de leur secteur.',
  },
];

export default valuesData;