import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './sections/Footer/Footer';
import Accueil from './pages/Accueil/Accueil';
import LegalMentions from './pages/LegalMentions/LegalMentions';
import Header from './sections/Header/Header';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <Header />
      <main className="content">
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/mentions-legales" element={<LegalMentions />} />
          <Route path="*" element={<div>Page non trouvée (404)</div>} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;