import React from 'react';
import AboutUs from '../../sections/AboutUs/AboutUs';
import Values from '../../sections/Values/Values';
import Solutions from '../../sections/Solutions/Solutions';
import EcosystemeEntreprise from '../../sections/EcosystemeEntreprise/EcosystemeEntreprise';
import Team from '../../sections/Team/Team';
import Contact from '../../sections/Contact/Contact';
import IntroBanner from '../../sections/IntroBanner/IntroBanner';

const Accueil = () => {
  return (
    <>
      <IntroBanner />
      <AboutUs />
      <Values />
      <Solutions />
      <video className="full-width-video" autoPlay loop muted>
        <source src="./video/building.mp4" type="video/mp4" />
        Votre navigateur ne supporte pas la vidéo.
      </video>
      <EcosystemeEntreprise />
      <Team />
      <Contact />
      <iframe
        title='map'
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5274.35036117975!2d2.4271246770384702!3d48.6256297170576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e5de1b09662f9f%3A0x4adde1e3877dd888!2s38%20Cr%20Blaise%20Pascal%2C%2091000%20%C3%89vry-Courcouronnes!5e0!3m2!1sfr!2sfr!4v1732006990674!5m2!1sfr!2sfr"
        height="450"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </>
  );
};

export default Accueil;