import React, { useEffect } from 'react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaLinkedin } from 'react-icons/fa';
import { BiSolidLike } from "react-icons/bi";
import './Contact.css';
import ContactForm from '../../components/ContactForm/ContactForm';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Contact = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <section id="contact" className="contact-section">
      <div data-aos="zoom-in" data-aos-duration="700" className="contact-intro">
        <h2>Contactez-nous</h2>
        <p>
          Chez <strong>Poly Swan Tech</strong>, nous sommes engagés dans l'innovation et la finance durable. Contactez-nous pour en savoir plus ou rejoignez-nous dans cette aventure.
        </p>
      </div>

      <div className="contact-body">
        <div className="contact-info">
          <div className="info-box">
          <div>
            <FaMapMarkerAlt />
            <h4>Adresse</h4>
            <a
              href="https://www.google.com/maps?q=38+Cours+Blaise+Pascal,+Evry-Courcouronnes"
              target="_blank"
              rel="noopener noreferrer"
            >
              38 Cours Blaise Pascal,<br></br> Evry-Courcouronnes
            </a>
            </div>
          </div>
          <div className="info-box">
            <div>
            <FaPhoneAlt />
            <h4>Téléphone</h4>
            <a href="tel:+33169236344">+33 1 69 23 63 44</a>
            </div>
          </div>
          <div className="info-box">
          <div>
            <FaEnvelope />
            <h4>Email</h4>
            <a href="mailto:contact@polyswantech.com">contact@polyswantech.com</a>
            </div>
          </div>
          <div className="info-box">
          <div>
            <BiSolidLike />
            <h4>Réseaux sociaux</h4>
            <a
              href="https://www.linkedin.com/company/poly-swan-tech"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link linkedin"
            >
              <FaLinkedin style={{ verticalAlign: 'middle' }} /> LinkedIn
            </a>
            </div>
          </div>
        </div>

        <div className="contact-form">
          <ContactForm />
        </div>
      </div>
    </section>
  );
};

export default Contact;